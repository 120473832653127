import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-send-verification-code',
  templateUrl: './send-verification-code.component.html',
  styleUrls: ['./send-verification-code.component.css']
})

export class SendVerificationCodeComponent implements OnInit {

  @Input() allowResend: boolean;
  @Output() activateResendCode: EventEmitter <boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  resendCode(): void {

    this.activateResendCode.emit();
  }

}
