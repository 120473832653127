<!-- Footer -->
<footer class="footer">
  <!-- Footer Top -->
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <!-- Footer Widget -->
          <div class="footer-widget footer-menu">
            <h2 class="footer-title">Quick Links</h2>
            <ul>
              <li>
                <a [routerLink]="'/pages/about-us'">About Us</a>
              </li>
              <li>
                <a [routerLink]="'/pages/contact-us'">Contact Us</a>
              </li>
              <!--
                ComingSoon: FAQ remove this line when adding the actual FAQs
                <li>
								<a [routerLink]="'/pages/faq'">FAQ</a>
							</li> -->
              <li>
                <a href="https://forum.thewealthmarket.com/">Forum</a>
              </li>
              <li>
                <a [routerLink]="'/pages/serviceprovider-page'"
                  >View Service Providers</a
                >
              </li>
            </ul>
          </div>
          <!-- /Footer Widget -->
        </div>
        <div class="col-lg-3 col-md-6">
          <!-- Footer Widget -->
          <div class="footer-widget footer-menu">
            <h2 class="footer-title">Categories</h2>
            <ul>
              <li>
                <a [routerLink]="'/pages/search'">Computer</a>
              </li>
              <li>
                <a [routerLink]="'/pages/search'">Interior</a>
              </li>
              <li>
                <a [routerLink]="'/pages/search'">Car Wash</a>
              </li>
              <li>
                <a [routerLink]="'/pages/search'">Cleaning</a>
              </li>
            </ul>
          </div>
          <!-- /Footer Widget -->
        </div>
        <div class="col-lg-3 col-md-6">
          <!-- Footer Widget -->
          <div class="footer-widget footer-contact">
            <h2 class="footer-title">Contact Us</h2>
            <div class="footer-contact-info">
              <div class="footer-address">
                <span><i class="far fa-building"></i></span>
                <p>
                  The LandMark (2nd floor), Km. 24 Lekki-Epe Express way,
                  VGC/Ikota Complex, Ajah, Lagos, Nigeria.
                </p>
              </div>
              <p><i class="fas fa-headphones"></i>+234 806 859 2750</p>
              <p class="mb-0">
                <i class="fas fa-envelope"></i>contact@thewealthmarket.com
              </p>
            </div>
          </div>
          <!-- /Footer Widget -->
        </div>
        <div class="col-lg-3 col-md-6">
          <!-- Footer Widget -->
          <div class="footer-widget">
            <h2 class="footer-title">Follow Us</h2>
            <div class="social-icon">
              <ul>
                <li>
                  <a href="javascript:void(0)" target="_blank"
                    ><i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" target="_blank"
                    ><i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" target="_blank"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.google.com/" target="_blank"
                    ><i class="fab fa-google"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!-- <div class="subscribe-form">
							<input type="email" class="form-control" placeholder="Enter your email">
							<button type="submit" class="btn footer-btn">
								<i class="fas fa-paper-plane"></i>
							</button>
						</div> -->
          </div>
          <!-- /Footer Widget -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Footer Top -->

  <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="container">
      <!-- Copyright -->
      <div class="copyright">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <div class="copyright-text">
              <p class="mb-0">
                Copyright &copy; 2022 TheWealthMarket.com. All rights reserved.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <!-- Copyright Menu -->
            <div class="copyright-menu">
              <ul class="policy-menu">
                <li>
                  <a [routerLink]="'/pages/terms-conditions'"
                    >Terms and Conditions</a
                  >
                </li>
                <li>
                  <a [routerLink]="'/pages/privacy-policy'">Privacy</a>
                </li>
              </ul>
            </div>
            <!-- /Copyright Menu -->
          </div>
        </div>
      </div>
      <!-- /Copyright -->
    </div>
  </div>
  <!-- /Footer Bottom -->
</footer>
<!-- /Footer -->
