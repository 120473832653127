<!-- <div class="error-container"  *ngIf="errorIs500; else otherErrors">
    <p class="error-message">Error 500: Internal Server Error</p>
  </div>
  <ng-template #otherErrors>
    <div class="error-container" *ngIf="errorMessage">
      <p class="error-message">{{ errorMessage }}</p>
    </div>
  </ng-template> -->
  <div *ngIf="errorMessage" class="error-container">
    <p>{{ errorMessage }}</p>
  </div>
