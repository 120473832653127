<div style="display: inline;">
  <div class="yellowStarContainer"  [title]="rating">
    <div *ngIf="rating === 5" style="width: 90px;" class="yellow-stars"> <!--This guy himself is showing 5 stars, but his parent is the one being cropped-->
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
    </div>

    <div *ngIf="rating === 4" style="width: 90px;" class="yellow-stars"> <!--This guy himself is showing 5 stars, but his parent is the one being cropped-->
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled emptyStar"></i>
    </div>

    <div *ngIf="rating === 3" style="width: 90px;" class="yellow-stars"> <!--This guy himself is showing 5 stars, but his parent is the one being cropped-->
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
    </div>

    <div *ngIf="rating === 2" style="width: 90px;" class="yellow-stars"> <!--This guy himself is showing 5 stars, but his parent is the one being cropped-->
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
    </div>

    <div *ngIf="rating === 1" style="width: 90px;" class="yellow-stars"> <!--This guy himself is showing 5 stars, but his parent is the one being cropped-->
      <i class="fas fa-star filled"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
    </div>

    <div *ngIf="rating === 0" style="width: 90px;" class="yellow-stars"> <!--This guy himself is showing 5 stars, but his parent is the one being cropped-->
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
      <i class="fas fa-star filled emptyStar"></i>
    </div>
  </div>

</div>
<!-- <span class="d-inline-block average-rating">({{rating}})</span> -->


<!-- <i class="fas fa-star filled"></i>
<i class="fas fa-star filled"></i>
<i class="fas fa-star filled"></i>
<i class="fas fa-star filled"></i>
<i class="fas fa-star filled"></i> -->
