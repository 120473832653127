import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.css']
})
export class ErrorHandlerComponent implements OnInit {

  // @Input() errorIs500: boolean = false;
  // @Input() errorMessage: string = '';
  @Input() errorMessage: string = '';

  constructor() { }

  ngOnInit(): void {
  }


}
