<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center mt-1 mb-2" >
  <p class="font-15 mb-0">Didn't Receive a Code?</p>
  <button type="button" class="font-15 mb-0 btn btn-outline-primary btn-sm"
    [disabled]="!allowResend"
    (click)="resendCode()"
  >
    Resend
  </button>
  <p *ngIf="!allowResend"><small>try again after 60 seconds</small></p>
  <p class="p-0 m-0"><small><b>Verification codes expire after one use</b></small></p>
</div>
