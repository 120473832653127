import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ShowNotificationService {
  constructor(public snackBar: MatSnackBar) {}

  /**
   * snackbar popup that gives the user some kind of information
   * @param colorName - color of the snackbar
   * @param text - text that is displayed to the user
   * @param placementFrom - vertical postion. value can only be 'top' or 'buttom'
   * @param placementAlign - horizontal position. value can only be 'left' or 'right
   */
  show(
    colorName: string,
    text: string,
    placementFrom: 'top' | 'bottom',
    placementAlign: 'left' | 'right'
  ) {
    this.snackBar.open(text, '', {
      duration: 4000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }
}
