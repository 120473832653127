import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AttributeService {
  constructor() {}

  /**
   * Admin side-bar methode
   */
  public adminsideMenuresponsive(): void {
    $(document).on('click', '#sidebar-menu a', function (e) {
      if ($(this).parent().hasClass('submenu')) {
        e.preventDefault();
      }
      if (!$(this).hasClass('subdrop')) {
        $('ul', $(this).parents('ul:first')).slideUp(350);
        $('a', $(this).parents('ul:first')).removeClass('subdrop');
        $(this).next('ul').slideDown(350);
        $(this).addClass('subdrop');
      } else if ($(this).hasClass('subdrop')) {
        $(this).removeClass('subdrop');
        $(this).next('ul').slideUp(350);
      }
    });

    $('body').append('<div class="sidebar-overlay"></div>');
    $(document).on('click', '#mobile_btn_admin', function () {
      var $wrapper = $('.main-wrapper');
      $wrapper.toggleClass('slide-nav');
      $('.sidebar-overlay').toggleClass('opened');
      $('html').addClass('menu-opened');
      return false;
    });

    // Sidebar overlay
    $('.sidebar-overlay').on('click', function () {
      var $wrapper = $('.main-wrapper');
      $wrapper.removeClass('slide-nav');
      $('.sidebar-overlay').removeClass('opened');
      $('html').removeClass('menu-opened');
    });
  }

  /**
   * user side-bar method
   */
  public usersidemenuresponsive(): void {
    $(document).on('click', '.main-nav a', function (e) {
      if ($(this).parent().hasClass('has-submenu')) {
        e.preventDefault();
      }
      if (!$(this).hasClass('submenu')) {
        $('ul', $(this).parents('ul:first')).slideUp(350);
        $('a', $(this).parents('ul:first')).removeClass('submenu');
        $(this).next('ul').slideDown(350);
        $(this).addClass('submenu');
      } else if ($(this).hasClass('submenu')) {
        $(this).removeClass('submenu');
        $(this).next('ul').slideUp(350);
      }
    });
    $('body').append('<div class="sidebar-overlay"></div>');
    $(document).on('click', '#mobile_btn', function () {
      $('.main-wrapper').toggleClass('slide-nav');
      $('.sidebar-overlay').toggleClass('opened');
      $('html').addClass('menu-opened');
      return false;
    });
    $(document).on('click', '.sidebar-overlay', function () {
      $('html').removeClass('menu-opened');
      $(this).removeClass('opened');
      $('.main-wrapper').removeClass('slide-nav');
    });

    $(document).on('click', '#menu_close', function () {
      $('html').removeClass('menu-opened');
      $('.sidebar-overlay').removeClass('opened');
      $('.main-wrapper').removeClass('slide-nav');
    });
  }
}
