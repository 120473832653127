import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-paragraph',
  templateUrl: './info-paragraph.component.html',
  styleUrls: ['./info-paragraph.component.css']
})
export class InfoParagraphComponent implements OnInit {
  @Input("note") note: String = "this is to show small notes or info to users";

  constructor() { }

  ngOnInit(): void {
  }

}
