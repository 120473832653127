import { StarComponent } from './starRatings.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { InfoParagraphComponent } from './components/info-paragraph/info-paragraph.component';
import { SendVerificationCodeComponent } from './components/send-verification-code/send-verification-code.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SortDirective } from '../directive/sort.directive';
import { ErrorHandlerComponent } from './components/error-handler/error-handler.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    InfoParagraphComponent,
    SendVerificationCodeComponent,
    LoadingSpinnerComponent,
    StarComponent,
    SortDirective,
    ErrorHandlerComponent

  ],
  imports: [CommonModule, MatSnackBarModule, MatProgressSpinnerModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CommonModule, LoadingSpinnerComponent, StarComponent, SortDirective, ErrorHandlerComponent
  ],
})
export class SharedModule {}
