import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css'],
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() public background: 'none';
  public loadingShade = {}
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.background !== 'none') {
      this.loadingShade = {
        background: 'rgba(0, 0, 0, 0.15)',
      };
    } 
  }
}
