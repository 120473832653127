import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { LoginHeaderComponent } from './common/login-header/login-header.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgSelect2Module } from 'ng-select2';
import { Daterangepicker } from 'ng2-daterangepicker';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FunctionService } from './shared/function.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';

import { ErrorInterceptor } from './shared/interceptor/error.interceptor';
import { RequestInterceptor } from './shared/interceptor/request.interceptor';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { ShowNotificationService } from './shared/services/show-notification.service';
import { SendVerificationCodeService } from './shared/services/send-verification-code.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UploadFileService } from './shared/services/upload-file.service';

// angular material import
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default,
};
// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FilterPipe } from './filter.pipe';
// import { SortDirective } from './directive/sort.directive';
import { OAuthModule } from 'angular-oauth2-oidc';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginHeaderComponent,
    FilterPipe,
    // SortDirective

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    ToastrModule.forRoot(),
    NgWizardModule.forRoot(ngWizardConfig),
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    Daterangepicker,
    NgSelect2Module,
    SharedModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatSortModule,
    NgbModule,
    MatFormFieldModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyD3m8eP5oW4SafnbMfGWw8UzZN44SWfLT0',
      authDomain: 'wealth-market.firebaseapp.com',
      projectId: 'wealth-market',
      storageBucket: 'wealth-market.appspot.com',
      messagingSenderId: '177631385743',
      appId: '1:177631385743:web:1e210431d8d92a8f8078fa',
    }),
    AngularFirestoreModule,
    AngularFireStorageModule,
    OAuthModule.forRoot()
  ],
  providers: [
    DatePipe,
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    FunctionService,
    ShowNotificationService,
    SendVerificationCodeService,
    UploadFileService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
