import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class UploadFileService {


  constructor(private http: HttpClient) { }


  upload(formData: FormData, path: string): Observable<HttpEvent<any>> {

    const req = new HttpRequest('POST', `${environment.apiUrl}/${path}`, formData, {
      reportProgress: true
    });

    return this.http.request(req);
  }



  getFiles(): Observable<any> {

    return this.http.get(`${environment.apiUrl}/files`);
  }
}