import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Injectable } from '@angular/core';

@Injectable()
export class  SendVerificationCodeService {

  constructor(
    private authenticationService: AuthenticationService
  ) { }


  startResendCountdown(controls) {
    setTimeout(async () => {
      controls.allowResend = true;
      console.log('can resend')
    }, 60000);
  }


  activateResendCode(email, controls, type) {
    this.authenticationService.sendCustomVerificationCode(email, type)
        .subscribe((resp => {

          if (resp.statusCode === 200) {
            controls.allowResend = false;
            this.startResendCountdown(controls);
          }
        }), error => {
          controls.allowResend = false;
          this.startResendCountdown(controls);
      });

  }
}
