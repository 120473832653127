import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  {
    path: 'index',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'material',
    loadChildren: () =>
      import('./demo-page/demo-page.module').then((m) => m.DemoPageModule),
  },
  {
    path: 'tree',
    loadChildren: () =>
      import('./demo-page/tree-module/tree-module.module').then(
        (m) => m.TreeModuleModule
      ),
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./categories/categories.module').then((m) => m.CategoriesModule),
  },
  {
    path: 'providers',
    loadChildren: () =>
      import('./providers/providers.module').then((m) => m.ProvidersModule),
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./customers/customers.module').then((m) => m.CustomersModule),
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'customers/jobs/new',
    loadChildren: () =>
      import('./customers/jobs/new/new.module').then((m) => m.NewModule),
  },
  {
    path: 'customers/jobs/job-proposal',
    loadChildren: () =>
      import('./customers/jobs/job-proposal/job-proposal.module').then(
        (m) => m.JobProposalModule
      ),
  },
  {
    path: 'customers/jobs/post-jobs-details',
    loadChildren: () =>
      import(
        './customers/jobs/post-jobs-details/post-jobs-details.module'
      ).then((m) => m.PostJobsDetailsModule),
  },
  {
    path: 'customers/jobs/jobs-application-details',
    loadChildren: () =>
      import(
        './customers/jobs/jobs-application-details/jobs-application-details.module'
      ).then((m) => m.JobsApplicationDetailsModule),
  },
  // {
  //   path: 'providers/profile-settings',
  //   loadChildren: () =>
  //     import(
  //       './providers/settings/profile-settings/profile-settings.module'
  //     ).then((m) => m.ProfileSettingsModule),
  // },
  {
    path: 'providers/profile-settings',
    loadChildren: () =>
      import(
        './providers/settings/settings.module'
      ).then((m) => m.SettingsModule),
  },
  {
    path: 'providers/job-invitation',
    loadChildren: () =>
      import('./providers/job-invitation/job-invitation.module').then(
        (m) => m.JobInvitationModule
      ),
  },
  {
    path: 'services/service-listing-details',
    loadChildren: () =>
      import(
        './services/service-listing-details/service-listing-details.module'
      ).then((m) => m.ServiceListingDetailsModule),
  },

  {
    path: 'services/jobs/post-jobs',
    loadChildren: () =>
      import('./services/jobs/post-jobs/post-jobs.module').then(
        (m) => m.PostJobsModule
      ),
  },
  {
    path: 'pages/new-notifications',
    loadChildren: () =>
      import('./pages/new-notifications/new-notifications.module').then(
        (m) => m.NewNotificationsModule
      ),
  },

  {
    path: 'pages/book-chat',
    loadChildren: () =>
      import('./pages/book-chat/book-chat.module').then(
        (m) => m.BookChatModule
      ),
  },
  {
    path: 'admin/service-provider-review',
    loadChildren: () =>
      import(
        './admin/service-provider-review/service-provider-review.module'
      ).then((m) => m.ServiceProviderReviewModule),
  },
  {
    path: 'admin/providers-profile/:id',
    loadChildren: () =>
      import('./admin/providers-profile/providers-profile.module').then(
        (m) => m.ProvidersProfileModule
      ),
  },
  {
    path: 'admin/messages',
    loadChildren: () =>
      import('./admin/messages/messages.module').then((m) => m.MessagesModule),
  },
  {
    path: 'admin/service-provider-profile',
    loadChildren: () =>
      import(
        './admin/service-provider-profile/service-provider-profile.module'
      ).then((m) => m.ServiceProviderProfileModule),
  },
  {
    path: 'admin/order-detail',
    loadChildren: () =>
      import('./admin/order-detail/order-detail.module').then(
        (m) => m.OrderDetailModule
      ),
  },
  {
    path: 'admin/add-a-service',
    loadChildren: () =>
      import('./admin/add-a-service/add-a-service.module').then(
        (m) => m.AddAServiceModule
      ),
  },
  {
    path: 'admin/services/service-details',
    loadChildren: () =>
      import('./admin/service-details/service-details.module').then(
        (m) => m.ServiceDetailsModule
      ),
  },
  {
    path: 'admin/customers-details/:id',
    loadChildren: () =>
      import('./admin/customers-details/customers-details.module').then(
        (m) => m.CustomersDetailsModule
      ),
  },
  {
    path: 'job-invitation',
    loadChildren: () =>
      import('./providers/job-invitation/job-invitation.module').then(
        (m) => m.JobInvitationModule
      ),
  },
  // { path: 'job-details', loadChildren: () => import('./services/jobs/post-jobs-details/post-jobs-details.module').then(m => m.PostJobsDetailsModule) },
  {
    path: 'admin/services',
    loadChildren: () =>
      import('./admin/services/services.module').then(
        (m) => m.SpecialitiesModule
      ),
  },

  {
    path: 'services/listings',
    loadChildren: () =>
      import('./services/listings/listings.module').then(
        (m) => m.ListingsModule
      ),
  },
  {
    path: 'admin/Refund',
    loadChildren: () =>
      import('./admin/refunds/refunds.module').then((m) => m.RefundsModule),
  },
  {
    path: 'customers/job-details',
    loadChildren: () =>
      import('./customers/job-details/job-details.module').then(
        (m) => m.JobDetailsModule
      ),
  },
  {
    path: 'providers/refund-request',
    loadChildren: () =>
      import('./providers/refund-request/refund-request.module').then(
        (m) => m.RefundRequestModule
      ),
  },
  {
    path: 'customers/providers-reviews',
    loadChildren: () =>
      import('./customers/providers-reviews/providers-reviews.module').then(
        (m) => m.ProvidersReviewsModule
      ),
  },


  {
    path: 'admin/job-application-list',
    loadChildren: () =>
      import('./admin/job-application-list/job-application-list.module').then(
        (m) => m.JobApplicationListModule
      ),
  },
  {
    path: 'admin/booked-jobs',
    loadChildren: () =>
      import('./admin/booked-jobs/booked-jobs.module').then(
        (m) => m.BookedJobsModule
      ),
  },
  {
    path: 'providers/my-review',
    loadChildren: () =>
      import('./providers/my-review/my-review.module').then(
        (m) => m.MyReviewModule
      ),
  },

  {
    path: 'services/listings',
    loadChildren: () =>
      import('./services/listings/listings.module').then(
        (m) => m.ListingsModule
      ),
  },

  {
    path: 'admin/Refund',
    loadChildren: () =>
      import('./admin/refunds/refunds.module').then((m) => m.RefundsModule),
  },
  {
    path: 'customers/job-details',
    loadChildren: () =>
      import('./customers/job-details/job-details.module').then(
        (m) => m.JobDetailsModule
      ),
  },
  {
    path: 'providers/refund-request',
    loadChildren: () =>
      import('./providers/refund-request/refund-request.module').then(
        (m) => m.RefundRequestModule
      ),
  },
  {
    path: 'customers/providers-reviews',
    loadChildren: () =>
      import('./customers/providers-reviews/providers-reviews.module').then(
        (m) => m.ProvidersReviewsModule
      ),
  },
  // { path: 'customers/jobs/updated', loadChildren: () => import('./customers/jobs/updated/updated.module').then(m => m.UpdatedModule) },


  {
    path: 'providers/job-invitation/job-invitation-details/:id',
    loadChildren: () =>
      import(
        './providers/job-invitation/job-invitation-details/job-invitation-details.module'
      ).then((m) => m.JobInvitationDetailsModule),
  },
  {
    path: 'providers/job-application',
    loadChildren: () =>
      import('./providers/job-application/job-application.module').then(
        (m) => m.JobApplicationModule
      ),
  },
  {
    path: 'pages/serviceprovider-page',
    loadChildren: () =>
      import('./pages/serviceprovider-page/serviceprovider-page.module').then(
        (m) => m.ServiceproviderPageModule
      ),
  },
  {
    path: 'pages/service-provider-details',
    loadChildren: () =>
      import(
        './pages/service-provider-details/service-provider-details.module'
      ).then((m) => m.ServiceProviderDetailsModule),
  },
  {
    path: 'customers/jobs/details-page',
    loadChildren: () =>
      import('./customers/jobs/details-page/details-page.module').then(
        (m) => m.DetailsPageModule
      ),
  },
  {
    path: 'admin/list-of-jobs-details/:id',
    loadChildren: () =>
      import('./admin/list-of-jobs-details/list-of-jobs-details.module').then
        (
          (m) => m.ListOfJobsDetailsModule

        )

  },

  { path: 'admin/list-of-jobs', loadChildren: () => import('./admin/list-of-jobs/list-of-jobs.module').then(m => m.ListOfJobsModule) },
  // { path: 'admin/list-of-jobs-details/:id', loadChildren: () => import('./admin/list-of-jobs-details/list-of-jobs-details.module').then(m => m.ListOfJobsDetailsModule) },
  { path: 'customers/refund-request', loadChildren: () => import('./customers/refund-request/refund-request.module').then(m => m.RefundRequestModule) },
  { path: 'admin/service-type', loadChildren: () => import('./admin/service-type/service-type.module').then(m => m.ServiceTypeModule) },
  { path: 'admin/add-service-type', loadChildren: () => import('./admin/add-service-type/add-service-type.module').then(m => m.AddServiceTypeModule) },
  { path: 'providers/services/list-aservice', loadChildren: () => import('./providers/services/list-aservice/list-aservice.module').then(m => m.ListAserviceModule) },
  { path: 'customers/create-job-offer', loadChildren: () => import('./customers/create-job-offer/create-job-offer.module').then(m => m.CreateJobOfferModule) },
  { path: 'customers/job-offer', loadChildren: () => import('./customers/job-offer/job-offer.module').then(m => m.JobOfferModule) },
  { path: 'providers/job-offers', loadChildren: () => import('./providers/job-offer/job-offer.module').then(m => m.JobOfferModule) },
  { path: 'customers/job-offer-details', loadChildren: () => import('./customers/job-offer-details/job-offer-details.module').then(m => m.JobOfferDetailsModule) },
  { path: 'admin/edit-service-type', loadChildren: () => import('./admin/edit-service-type/edit-service-type.module').then(m => m.EditServiceTypeModule) },
  { path: 'providers/profile', loadChildren: () => import('./providers/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'providers/job-offer-details', loadChildren: () => import('./providers/job-offer-details/job-offer-details.module').then(m => m.JobOfferDetailsModule) },
  { path: 'customers/all-jobs', loadChildren: () => import('./customers/all-jobs/all-jobs.module').then(m => m.AllJobsModule) },
  { path: 'providers/services/custom-service', loadChildren: () => import('./providers/services/custom-service/custom-service.module').then(m => m.CustomServiceModule) },
  { path: 'providers/services/hotel-listing', loadChildren: () => import('./providers/services/hotel-listing/hotel-listing.module').then(m => m.HotelListingModule) },
  { path: 'pages/hotel-details', loadChildren: () => import('./pages/hotel-details/hotel-details.module').then(m => m.HotelDetailsModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
