import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  // This is a private property that holds the Socket.IO client instance.
  private socket: Socket;
  // This is the URL where the Socket.IO server is hosted
  // private url = 'http://localhost:3000';
  // private usersUrl = 'http://localhost:3000/api/users';
  // private messagesUrl = 'http://localhost:3000/api/messages';
  private url = 'https://ca.thewealthmarket.com';
  private usersUrl = 'https://ca.thewealthmarket.com/api/users';
  private messagesUrl = 'https://ca.thewealthmarket.com/api/messages';

  // The constructor initializes the service. It creates an instance of the Socket.IO client and connects it to the specified server URL. It also specifies which transports can be used
  constructor(private http: HttpClient) {
    this.socket = io(this.url, {
      transports: ['websocket', 'polling', 'flashsocket'],
    });
  }

  // This method is used to join a specific chat room.
  joinRoom(data: any): void {
    this.socket.emit('join', data);
  }

  // This method takes a data parameter and emits a 'message' event to the server using the Socket.IO client. This is used to send a message to the chat room.
  sendMessage(data: any): void {
    this.socket.emit('message', data);
  }

  getUsersMessage(): Observable<any[]> {
    return this.http.get<any[]>(this.messagesUrl);
  }

  getUserFilteredMessages(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/api/users-new`);
  }
  getUnreadMessages(userId: any, roomId: any): Observable<any> {
    return this.http.get<any>(`${this.url}/api/unread-messages-count/${userId}/${roomId}`);
  }
 
  markMessagesAsRead(data: { roomId: string, userId: number }): Observable<any> {
    return this.http.post(`${this.url}/api/messages/read`, data);
  }
  

  sendUserList(usersData: any): void {
    this.socket.emit('sendUsersData', usersData);
  }

  updateUserData(userId: number, updatedUserData: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.usersUrl}/${userId}`,
      updatedUserData,
      httpOptions
    );
  }

  getUpdatedUser(userId: string): Observable<any> {
    const url = `${this.usersUrl}/${userId}`;
    return this.http.get<any>(url);
  }

  // Method to fetch users from the Node.js backend
  getUsersList(): Observable<any[]> {
    return this.http.get<any[]>(this.usersUrl);
  }

  // This method returns an observable that can be subscribed to in order to receive messages from the server
  getMessage(): Observable<any> {
    return new Observable<{
      user: string;
      message: string;
      userId: string;
      file: string;
    }>((observer) => {
      this.socket.on('new message', (data) => {
        observer.next(data);
      });

      return () => {
        this.socket.disconnect();
      };
    });
  }

  // This method retrieves chat data from local storage. It checks if there is any data stored under the key 'chats' and returns it. If no data is found, it returns an empty array.
  getStorage() {
    const storage: any = localStorage.getItem('chats');
    return storage ? JSON.parse(storage) : [];
  }

  // This method stores chat data in local storage. It takes a data parameter, converts it to a JSON string, and stores it under the key 'chats'.
  setStorage(data: any) {
    localStorage.setItem('chats', JSON.stringify(data));
  }

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(`${this.url}/upload`, formData);
  }

  // Function to send a message with or without a file
  sendFile(
    userId: string,
    user: string,
    fileId?: string,
    room?
  ): Observable<any> {
    const body = {
      userId,
      user,
      fileId,
      room,
    };

    return this.http.post<any>(this.messagesUrl, body);
  }
}
