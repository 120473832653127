import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewEncapsulation,
  AfterViewChecked,
} from '@angular/core';
import {
  Event,
  NavigationStart,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Location, PlatformLocation } from '@angular/common';
import { CommonServiceService } from './common-service.service';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from './shared/services/communication.service';
import { CurrentUser } from './shared/interfaces/currentUser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoadingService } from './shared/state/loading.service';
import { tap } from 'rxjs/operators';
import { AttributeService } from 'src/app/shared/services/attribute.service';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from 'src/app/sso.config';
import { AuthenticationService } from './authentication/authentication.service';
import { IUserClaims } from './shared/interfaces/userObject';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewChecked {
  url;
  loadFooter = false;
  show: boolean = true;
  hideFooter: boolean = false;
  splitVal;
  base = '';
  page = '';

  authenticated: boolean = false;

  currentUser: IUserClaims;
  isloading$ = this.loadingService.isLoading$;
  userId: string;
  constructor(
    private activeRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private communicationService: CommunicationService,
    public Router: Router,
    location: Location,
    private toastr: ToastrService,
    public commonService: CommonServiceService,
    platform: PlatformLocation,
    private attribute: AttributeService,
    private loadingService: LoadingService,
    private oauthService: OAuthService,
    private router: Router,
    private authService: AuthenticationService,
  ) {
    // this.authService.attemptLoginWithDelay();
    this.configureSingleSignOn();

    Router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // $('html').removeClass('menu-opened');
        // $('.sidebar-overlay').removeClass('opened');
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
        if (this.base === 'admin') {
          this.show = false;
          this.hideFooter = true;
        } else {
          if (event.url === '/message') {
            this.hideFooter = true;
          }
        }
      }

    });
    // communicationService.changeEmitted$.subscribe((data) => {
    //   if (data.hasOwnProperty('authenticated')) {
    //     this.authenticated = data.authenticated;
    //   }
    // });






  }
  ngOnInit() {
    //watches the oauthService's events for when there's a token. Once there is, it runs things that get the provider and user usable.
    this.oauthService.events.subscribe(
      (event) => {
        if (this.oauthService.hasValidAccessToken()){
          this.confirmTokenExistence();
          this.getUserObject();
        } else {
          localStorage.clear();
        }
      }
    );



    setTimeout(() => (this.loadFooter = true), 2000);
    this.isloading$ = this.isloading$.pipe(
      tap((loadingState) => {
        // console.log('footer loading state ', loadingState);
        if (loadingState === true) {
          this.hideFooter = true;
        } else {
          this.hideFooter = false;
        }
      })
    );
    this.attribute.usersidemenuresponsive();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (this.currentUser !== null && this.currentUser !== undefined) {
      this.authenticated = true;
      // console.log('true; current user ', this.currentUser);
    }
    else {
      this.authenticated = false;
      // console.log('false; current user ', this.currentUser);
    }

  }


  configureSingleSignOn(){
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
      // if (!this.oauthService.hasValidAccessToken()) {
      //   this.oauthService.initImplicitFlow();
      // }
    });

  }

  login(){
    this.oauthService.initImplicitFlow();
  }

  logout(){
    this.oauthService.logOut();
    this.router.navigate(["/"]);
  }

  // get token(){
  //   let claims:any = this.oauthService.getIdentityClaims();
  //   return claims? claims : null;
  // }

  setToken(){
    console.log(this.oauthService.getAccessToken());
    const token = this.oauthService.getAccessToken();
    localStorage.setItem('userToken', JSON.stringify(token));
  }
  confirmTokenExistence(){
    if (this.oauthService.hasValidAccessToken()) {
      // alert("UHN");
      let claims: any = this.oauthService.getIdentityClaims();
      console.log("Token Claims: ", claims);
      localStorage.setItem('userObject', JSON.stringify(claims));
      //set token here
      this.setToken();
      //set provider here
      this.authService.cacheServicerovider();
      return claims ? claims : null;
    } else {
      console.log('No valid access token. User might not be authenticated.');
      return null;
    }
  }

  getUserObject(){
    const user: IUserClaims = JSON.parse(localStorage.getItem("userObject"));
    this.currentUser = user;
    console.log("User object: " , this.currentUser);
    this.userId = this.currentUser['wm-unique-id'];
    console.log("User's unique Id: " , this.userId);
  }

}
