<header class="header">
    <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
            <a id="mobile_btn" href="javascript:void(0);">
                <span class="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </a>
            <a [routerLink]="'/'" class="navbar-brand logo d-flex">
                <img src="https://forum.thewealthmarket.com/uploads/db2453/original/1X/5a050172eab982b05a8654220c1a4b1380ce2fa3.jpeg" class="img-fluid" alt="Logo">
                <h4 class="text-white ml-2">WM Service <span style="color: #FFCD38;">MarketPlace</span></h4>
            </a>
        </div>
        <div class="main-menu-wrapper">
            <div class="menu-header">
                <a [routerLink]="'/'" class="menu-logo">
                    <img src="https://forum.thewealthmarket.com/uploads/db2453/original/1X/5a050172eab982b05a8654220c1a4b1380ce2fa3.jpeg" class="img-fluid" alt="Logo">
                </a>
                <a id="menu_close" class="menu-close" href="javascript:void(0);">
                    <i class="fas fa-times"></i>
                </a>
            </div>
        </div>
    </nav>
</header>
