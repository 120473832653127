import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only add token to request if it's not an authentication request
    if (this.isApiUrl(request)) {
      request = this.addToken(request);
    }
    return next.handle(request);
  }

  // Check whether the request is for the authentication server
  private isApiUrl(request: HttpRequest<any>): boolean {
    const urls = [
      environment.apiUrl,
      environment.usrUrl,
      environment.pcrUrl,
      environment.adrsUrl,
      environment.miscUrl,
      environment.mAcctUrl,
      environment.notificationUrl
    ];
    // Check if the request URL starts with any of the URLs in the list
    return urls.some(url => request.url.startsWith(url));
  }
  // Adds the token to your headers if it exists and request is not for authentication
  private addToken(request: HttpRequest<any>) {
    const userToken = JSON.parse(localStorage.getItem("userToken"));
    if (userToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${userToken}`,
        }
      });
    }
    return request;
  }
}
