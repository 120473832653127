import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public authenticationService: AuthenticationService, public router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(retry(1),
      catchError(this.handleError.bind(this))
    );
  }
  handleError(error: HttpErrorResponse) {
    if (error.status === 401) {

      //remove the logged in user details
      localStorage.removeItem('currentUser');

      //get the link of the current page
      let returnUrl = location.href.split("#")[1];

      //set the link as the return url if its not an authentication page
      if (returnUrl) {
        this.router.navigate([''], { queryParams: { returnUrl } });
      } else {
        this.router.navigate(['']);
      }
    }

    //return our custom error response insted of the browser error object
    return throwError(error.error);
  }
}
