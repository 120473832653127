import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable, of } from 'rxjs';
import { CurrentUser, User } from '../interfaces/currentUser';
import { ChatListContact } from '../interfaces/messages';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  currentUser: CurrentUser;
  unreadMessagesCollection$: Observable<any>;
  constructor(
    private afs: AngularFirestore,
  ) { }

  // chat user cout
  unreadChatMessage(uniqueId): Observable<ChatListContact[] | any[]> {
    if (uniqueId === undefined) {
      return;
    }
    return this.afs
      .collection('chatUsers', (ref) =>
        ref
          .where('chatRoom', 'array-contains', uniqueId)
          .where('unread', '==', true)
          .orderBy('createdAt', 'desc')
          .limit(1)
      )

      .valueChanges() as Observable<ChatListContact[]>;

  }
}
