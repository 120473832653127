import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';


@Injectable({
  providedIn: 'root',
})


export class ScreenSizeService {
  screenSize: BreakpointObserver

  constructor(private breakpointObserver: BreakpointObserver) {
    this.screenSize =  breakpointObserver
  }
}
